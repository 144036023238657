import React from 'react'
import { Button } from '@material-ui/core';

import styles from './CookieBanner.module.scss'
import { config, publicUrl } from 'src/config'
import { useCookies } from 'react-cookie';

const oneYearFromNow = new Date();
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

const cookieOptions = {
  name: 'ov-cookie-consent',
  values: {
    accepted: 'accepted',
    declined: 'declined',
  },
  options: {
    path: '/',
    domain: config.cookieDomain,
    secure: false,
    expires: oneYearFromNow,
  }
} as const;

const getConsentState = (consentCookie: string) => {
  if (typeof consentCookie === 'string') {
    return consentCookie as 'accepted' | 'declined';
  }
  return 'pending';
}

export const CookieBanner: React.FC = ({ children }) => {
  const [cookies, setCookie] = useCookies([cookieOptions.name]);

  const consentState = getConsentState(cookies[cookieOptions.name]);

  const accept = () => {
    setCookie(
      cookieOptions.name,
      cookieOptions.values.accepted,
      cookieOptions.options
    );
  }

  const decline = () => {
    setCookie(
      cookieOptions.name,
      cookieOptions.values.declined,
      cookieOptions.options
    );
  }

  switch (consentState) {
    case 'accepted':
      return (
        <>
          {children}
        </>
      )
    case 'declined':
      return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <img
          src={publicUrl('img/cookie-256.png')}
          draggable={false}
          title='cookie'
        />
        <div className={styles.text}>
          <h2>
            Ez az oldal sütiket használ
          </h2>
          <p>
            A felhasználói élmény javításához és a bejelentkezési funkcióhoz
            az oldal sütiket használ. Az "Elfogadás" gombra kattintva hozzájárul
            a sütik használatához. További részletekért olvassa el&nbsp;
            <a
              target='_blank'
              href='https://static.orvosvalasz.hu/legal/orvosvalasz_adatkezelesi_v1.0.pdf'
            >
              adatkezelési tájékoztatónkat
            </a>.
          </p>
        </div>
        <div className={styles.buttonGroup}>
          <Button
            onClick={decline}
            disableElevation
            className={[styles.button].join(' ')}
          >
            Elutasítás
          </Button>
          <Button
            onClick={accept}
            disableElevation
            className={[styles.button, styles.cta].join(' ')}
          >
            Elfogadás
          </Button>
        </div>
      </div>
    </div>
  )
}
