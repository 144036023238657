import urls from './urls'
import * as Types from './types'
import { config } from 'src/config'

export const baseHeaders = {
  'Content-Type': 'application/json',
  'X-Api-Key': config.apikey,
}

export const Account = {
  register: async (
    email: string,
    passwordHash: string,
    doctor: boolean,
    verificationID?: string
  ) => {
    try {
      const res = await fetch(urls.register, {
        method: 'POST',
        headers: {
          ...baseHeaders,
        },
        body: JSON.stringify({
          email: email,
          password_hash: passwordHash,
          doctor: doctor,
          verification_id: verificationID,
        }),
      })
      return (await res.json()) as Types.LoginResponse
    } catch {
      return Types.loginError
    }
  },

  verify: async (emailVerificationKey: string) => {
    try {
      const res = await fetch(urls.verify, {
        method: 'POST',
        headers: {
          ...baseHeaders,
        },
        body: JSON.stringify({
          email_verification_key: emailVerificationKey,
        }),
      })
      return (await res.json()) as Types.LoginResponse
    } catch {
      return Types.loginError
    }
  },

  login: async (email: string, passwordHash: string) => {
    try {
      const res = await fetch(urls.login, {
        method: 'POST',
        headers: {
          ...baseHeaders,
        },
        body: JSON.stringify({ email: email, password_hash: passwordHash }),
      })
      return (await res.json()) as Types.LoginResponse
    } catch {
      return Types.loginError
    }
  },

  autoLogin: async (sessionKey: string) => {
    try {
      const res = await fetch(urls.auth, {
        method: 'POST',
        headers: {
          ...baseHeaders,
          'User-Token': sessionKey,
        },
      })
      return (await res.json()) as Types.GenericResponse
    } catch {
      return Types.loginError
    }
  },

  resetPassword: async (email: string) => {
    try {
      const res = await fetch(urls.resetPassword, {
        method: 'POST',
        headers: {
          ...baseHeaders,
        },
        body: JSON.stringify({ email: email }),
      })
      return (await res.json()) as Types.GenericResponse
    } catch {
      return Types.loginError
    }
  },

  updatePassword: async (token: string, passwordHash: string) => {
    try {
      const res = await fetch(urls.updatePassword, {
        method: 'POST',
        headers: {
          ...baseHeaders,
        },
        body: JSON.stringify({ token: token, password_hash: passwordHash }),
      })
      return (await res.json()) as Types.LoginResponse
    } catch {
      return Types.loginError
    }
  },
}

export const Document = {
  validateDocument: async (documentID: string) => {
    const res = await fetch(`${urls.validateDocument}?id=${documentID}`, {
      method: 'GET',
      headers: {
        ...baseHeaders,
      },
    })
    return (await res.json()) as Types.GenericResponse
  },

  documentURL: (documentID: string) => {
    return `${urls.document}?id=${documentID}`
  },
}
