import React, { useState, useEffect } from 'react'
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'

// TODO: watch out for this bad boi (fixes findDOMNode depr. errors)
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles'

import { ContextType, DialogType } from 'src/common/types'
import { PageBase, HomePage, DocumentPage } from 'src/pages'
import { API } from 'src/api'
import { config } from 'src/config'
import { CookieBanner } from './components'
import { ExternalScripts } from './components/CookieBanner/ExternalScripts'

export const GlobalContext = React.createContext<ContextType>({} as ContextType)

function App() {
  const [sessionKey, setSessionKey] = useState('')
  const [passwordResetToken, setPasswordResetToken] = useState('')
  const [lastEmail, setLastEmail] = useState('')
  const [currentDialog, setCurrentDialog] = useState<DialogType>('none')

  useEffect(() => {
    async function verifyEmail(key: string) {
      const res = await API.Account.verify(key)
      if (res.status.result === 'ok') {
        let date = new Date()
        date.setFullYear(date.getFullYear() + 1)
        document.cookie =
          'session_key=' +
          res.sessionKey +
          '; expires=' +
          date.toUTCString() +
          '; path=/; domain=orvosvalasz.hu'
        window.location.replace(config.appURL)
      } else {
        console.log('invalid verification key')
      }
    }

    // Password reset token
    const params = new URLSearchParams(window.location.search)
    const passwordToken = params.get('password-reset-token')
    if (passwordToken) {
      setPasswordResetToken(passwordToken)
      setCurrentDialog('updatePassword')
    }

    // Email verification key
    const verificationKey = params.get('verification-key')
    if (verificationKey) verifyEmail(verificationKey)
  }, [])

  const ctx: ContextType = {
    sessionKey,
    setSessionKey,
    currentDialog,
    setCurrentDialog,
    passwordResetToken,
    lastEmail,
    setLastEmail,
  }

  const appTheme = createMuiTheme({
    palette: {
      primary: { main: '#47cdff' },
    },
    typography: {
      fontFamily: ['Nunito Sans', 'Sans Serif'].join(', '),
    },
  })

  return (
    <>
      <ThemeProvider theme={appTheme}>
        <GlobalContext.Provider value={ctx}>
          <Router>
            <PageBase>
              <Switch>
                <Route exact path='/'>
                  <HomePage />
                </Route>
                <Route exact path='/document/:documentID'>
                  <DocumentPage />
                </Route>
                <Route>
                  <Redirect to='/' />
                </Route>
              </Switch>
            </PageBase>
          </Router>
          <CookieBanner>
            <ExternalScripts />
          </CookieBanner>
        </GlobalContext.Provider>
      </ThemeProvider>
    </>
  )
}

export default App
