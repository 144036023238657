
import { Helmet } from 'react-helmet';
import { publicUrl } from 'src/config';

export const ExternalScripts = () => {

  return (
    <Helmet>
      {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-2ZBDRSQ6SZ"></script>
      <script src={publicUrl('script/gtag.js')}></script>

      {/* <!-- Hotjar Tracking Code for OrvosVálasz landing page --> */}
      <script src={publicUrl('script/hotjar.js')}></script>
    </Helmet>
  )
}
